import {Dialog, Transition} from "../../headlessui";
import React, {FC, Fragment} from "react";
import QRCode from "react-qr-code";

export interface ModalViewAllReviewsProps {
    show: boolean;
    onCloseModalViewAllReviews: () => void;
    value: string;
}

// const getOS = (): string | null => {
//     const userAgent: string = window.navigator.userAgent;
//     // Check for the presence of userAgentData and fallback to userAgent
//     const platform: string = 'userAgentData' in navigator ? (navigator as any).userAgentData.platform : navigator.platform;
//
//     const macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
//     const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
//     const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
//
//     let os: string | null = null;
//
//     if (macosPlatforms.indexOf(platform) !== -1) {
//         os = 'Mac OS';
//     } else if (iosPlatforms.indexOf(platform) !== -1) {
//         os = 'iOS';
//     } else if (windowsPlatforms.indexOf(platform) !== -1) {
//         os = 'Windows';
//     } else if (/Android/.test(userAgent)) {
//         os = 'Android';
//     } else if (/Linux/.test(platform)) {
//         os = 'Linux';
//     }
//
//     return os;
// };

const ModalViewQR: FC<ModalViewAllReviewsProps> = ({
                                                       show, onCloseModalViewAllReviews, value
                                                   }) => {
    let parts = value.split('$'); // split the string
    let smdpServer = parts[1]; // get the smdpServer
    let activationCode = parts[2]; // get the activationCode

    return (
        <Transition appear show={show} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={onCloseModalViewAllReviews}
                onClick={onCloseModalViewAllReviews}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block py-8 h-screen w-full max-w-5xl">
                            <QRCode
                                style={{display: 'inline'}}
                                // size={120}
                                value={value}
                            />
                            <div className="">
                                <div>
                                    <div><br/></div>
                                    <div>SMDP Server: {smdpServer}</div>
                                    <div>Activation Code: {activationCode}</div>
                                    <div><br/></div>
                                    <div>Full URL (usually for Android phones): {value}</div>

                                </div>
                            </div>
                        </div>

                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ModalViewQR;
