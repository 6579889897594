import {FC} from "react";
import {Disclosure} from "@headlessui/react";
import {MinusIcon, PlusIcon} from "@heroicons/react/24/outline";
import {translate} from "react-jhipster";

const FAQ_DATA = [
        {
            "name": "esim.menu-1",
            "content": "esim.menu-1-details"
        },
        {
            "name": "esim.menu-2",
            "content": "esim.menu-2-details"
        },
        {
            "name": "esim.menu-3",
            "content": "esim.menu-3-details"
        },
        {
            "name": "esim.menu-4",
            "content": "esim.menu-4-details"
        },
        {
            "name": "esim.menu-5",
            "content": "esim.menu-5-details"
        },
        {
            "name": "esim.menu-6",
            "content": "esim.menu-6-details"
        },
        {
            "name": "esim.menu-7",
            "content": "esim.menu-7-details"
        },
        {
            "name": "esim.menu-8",
            "content": "esim.menu-8-details"
        },
        {
            "name": "esim.menu-9",
            "content": "esim.menu-9-details"
        },
        {
            "name": "esim.menu-10",
            "content": "esim.menu-10-details"
        }
    ]
;

interface Props {
    panelClassName?: string;
    data?: typeof FAQ_DATA;
}

const FAQAccordionInfo: FC<Props> = ({
                                         panelClassName = "p-4 pt-3 last:pb-0 text-slate-600 text-sm dark:text-slate-300 leading-6",
                                         data = FAQ_DATA,
                                     }) => {
    return (
        <div className="w-full rounded-2xl space-y-2.5">
            {data.map((item, index) => {
                return (
                    <Disclosure key={index} defaultOpen={index === 0}>
                        {({open}) => (
                            <>
                                <Disclosure.Button
                                    className="flex items-center justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
                                    <span>{translate(item.name)}</span>
                                    {!open ? (
                                        <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400"/>
                                    ) : (
                                        <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400"/>
                                    )}
                                </Disclosure.Button>
                                <Disclosure.Panel
                                    className={panelClassName}
                                    as="div"
                                    dangerouslySetInnerHTML={{__html: translate(item.content)}}
                                ></Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                );
            })}
        </div>
    );
};

export default FAQAccordionInfo;
