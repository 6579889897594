import Logo from "../header/Logo";
import React, {useEffect, useRef} from "react";
import {CustomLink} from "./types";
import {translate} from "react-jhipster";
import {useDispatch} from "react-redux";
import {getPopularCountries} from "../../app/reducers/popular-countries.reducer";
import {useAppDispatch, useAppSelector} from "../../app/config/localStorage";
import {Link} from "react-router-dom";

export interface WidgetFooterMenu {
    id: string;
    title: string;
    menus: CustomLink[];
}



const Footer: React.FC = () => {
    const dispatch = useAppDispatch();
    const initialized = useRef(false)
    const popularCountriesLoading = useAppSelector(state => state.popularCountries.updateLoading);
    const popularCountries = useAppSelector(state => state.popularCountries.popularCountries);


    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            dispatch(getPopularCountries());
        } else {
            if(!popularCountriesLoading){
                dispatch(getPopularCountries());
            }
        }
    }, [dispatch, popularCountriesLoading]);

    const extractPopularCountries = () => {
        if(!popularCountries) return [];
        const length = popularCountries.length > 3 ? 3 : popularCountries.length;
        return popularCountries.slice(0, length).map((country: string) => {
            return {href: `/?country=${country}`, label: `global.countries.${country}`};
        });
    }

    const widgetMenus: WidgetFooterMenu[] = [
        {
            id: "5",
            title: "footer.popular-destinations",
            menus: extractPopularCountries(),
        },
        {
            id: "1",
            title: "footer.getting-started",
            menus: [
                {href: "/faq/first-use", label: "footer.submenu.first-use"},
                {href: "/esim", label: "footer.submenu.about-esim"},
                {href: "/compatible-devices", label: "footer.submenu.device-support"},

            ],
        },
        {
            id: "2",
            title: "footer.about-us",
            menus: [
                {href: "/contact", label: "footer.submenu.get-in-touch"},
                {href: "/terms-and-conditions", label: "footer.submenu.terms-and-conditions"},
                {href: "/privacy-policy", label: "footer.submenu.privacy-policy"},
                {href: "/cookie-policy", label: "footer.submenu.cookie-policy"},

            ],
        },
    ];

    const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
        return (
            <div key={index} className="text-sm bottom-0">
                <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
                    {translate(menu.title)}
                </h2>
                <ul className="mt-4 space-y-2">
                    {menu.menus.map((item, index) => (
                        <li key={index}>
                            <Link
                                key={index}
                                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                                to={item.href}
                                rel="noopener noreferrer"
                            >
                                {translate(item.label)}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <div className="nc-Footer relative py-12 lg:pt-12 lg:pb-12 border-t border-neutral-200 dark:border-neutral-700">
            <div
                className="container grid grid-cols-4 gap-y-10 gap-x-5 ">
                <div className="grid col-span-1 gap-5 ">
                    <div className="col-span-1">
                        <Logo/>
                    </div>
                    {/*<div className="col-span-2 flex items-center md:col-span-3">*/}
                    {/*  /!*<SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />*!/*/}
                    {/*</div>*/}
                </div>
                {widgetMenus.map(renderWidgetMenuItem)}
            </div>
        </div>
    );
};

export default Footer;
